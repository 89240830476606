<template>
  <div id="leftnavContent">
    <secondary-navigation :menu-entries="menuEntries"></secondary-navigation>
  </div>
</template>

<script>
  import SecondaryNavigation from '@/components/SecondaryNavigation.vue';

  export default {
    data: function() {
      return {
        menuEntries: [
          {
            title: "Getting Started",
            image: require("@/assets/thegame-menu/getting-started.png"),
            links: [
              { title: "What is LEGO Universe?", href: "/the-game/getting-started" },
              { title: "Free to Play", href: "/the-game/getting-started/free-to-play" },
              { title: "Membership", href: "/the-game/getting-started/membership" },
            ]
          },
          {
            title: "The Game",
            image: require("@/assets/thegame-menu/the-game.png"),
            links: [
              { title: "Building", href: "/the-game/build-anything" },
              { title: "Playing", href: "/the-game/play" },
              { title: "Connect with Friends", href: "/the-game/connect" },
              { title: "Leveling", href: "/the-game/leveling" },
            ]
          },
          {
            title: "The Universe",
            image: require("../assets/thegame-menu/the-universe.png"),
            links: [
              { title: "The Story", href: "/the-game/story" },
              { title: "The Factions", href: "/the-game/characters" },
              { title: "The Worlds", href: "/the-game/world" },
            ]
          }
        ]
      }
    },
    components: {
      SecondaryNavigation,
    }
  }
</script>
