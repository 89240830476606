<template>
  <div id="siteContainer">
    <div id="mainHeading">
      <div class="header">
        <h1><router-link to="/the-game">Game Info</router-link></h1>
        <breadcrumbs></breadcrumbs>
      </div>
    </div>
    <router-view id="mainColumn1" />
    <div id="mainColumn2">
      <the-game-menu></the-game-menu>
      <div id="leftnavTouts" style="height: 1005px;">
        <div class="column-round transparent">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="column-round-body">
            <div class="leftnav-tout">
              <router-link to="/community/news-network/s/318012">
                <img src="../assets/left-nav-touts/nexus-tower-live-now.png" title="Available Now">
              </router-link>
            </div>
            <div class="leftnav-tout">
              <router-link to="/parents/wheretobuy/234243">
                <img src="../assets/left-nav-touts/game-cards-are-here.png" title="Game cards">
              </router-link>
            </div>
            <div class="leftnav-tout">
              <router-link to="/shop-55000">
                <img src="../assets/left-nav-touts/game-dvd-available.png" title="Available Now">
              </router-link>
            </div>
            <div class="leftnav-tout">
              <router-link to="/newsletter">
                <img src="../assets/left-nav-touts/newsletter-sign-up.png" title="Sign up for the LEGO Universe newsletter">
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
  import TheGameMenu from '@/components/TheGameMenu.vue';

  export default {
    components: {
      TheGameMenu
    },
    data: function() {
      return {}
    }
  }
</script>

<style lang="scss">

</style>
